import { IonCol, IonGrid, IonRow, useIonViewDidEnter } from '@ionic/react';
import { Me, Carded, Header, MenuButton, Button } from 'components';
import { arrowBack, list, arrowForward, car, paperPlane, send, wallet, checkmark, close, trash, person, settings, listOutline, sendOutline, logOut } from 'ionicons/icons';
import { DielContext } from 'core/DielContext';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useContext } from 'react';
import { logout, refreshUser } from 'reducers/UserSlice';
import { currency, prettyText, cycleCalculator } from 'utils/Helper';
import { FirebaseService } from 'services';
import { uuidv4 } from '@firebase/util';

const Account: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((states: any) => states.user);
  const { setShowTabBar } = useContext(DielContext);
  const { auth } = FirebaseService;

  useIonViewDidEnter(() => {
    console.log('auth.currentUse', auth.currentUser);
    setShowTabBar(true);
    dispatch(refreshUser({}));
  });

  return (
    <Carded
      header={<Header title="Account" />}
      content={(
        <div>
          <div className="ion-margin-bottom">
            <div>
              Name: {`${user.lastname}, ${user.firstname} ${user.middlename}`}
            </div>
            <div className="textSmall">
              ID: {user.uid}
            </div>
            <div className="textSmall">
              Type: {`${user.type}`}
            </div>
            <div className="textSmall">
              Email: {`${user.email}`}
            </div>
          </div>

          <IonGrid className="ion-margin-bottom">
            <IonRow className="ion-text-center">
              <IonCol className="">{currency(user.balance)} <div className="title">Balance</div></IonCol>
              <IonCol className="">{currency(user.onholdBalance || 0)}<div className="title">On Hold</div></IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid className="ion-margin-bottom">
            <IonRow>
              <IonCol size="3">
                <MenuButton routerLink="/profile" label="Profile" icon={person} />
              </IonCol>
              <IonCol size="3">
                <MenuButton routerLink="/settings" label="Settings" icon={settings} />
              </IonCol>
              <IonCol size="3">
                <MenuButton routerLink="/activities" label="Activities" icon={list} />
              </IonCol>
              <IonCol size="3">
                <MenuButton routerLink="/transactions" label="Transactions" icon={listOutline} />
              </IonCol>
              <IonCol size="3">
                <MenuButton routerLink="/withdrawals/pending" label="Withdrawals" icon={sendOutline} />
              </IonCol>
            </IonRow>
          </IonGrid>

          <h5>Order Summary</h5>

          <IonGrid>
            <IonRow>
              <IonCol size="8">Members</IonCol>
              <IonCol size="4" className="ion-text-right">{user.members}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="8">Order Count</IonCol>
              <IonCol size="4" className="ion-text-right">{user.orders}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="8">Next Cycle Required</IonCol>
              <IonCol size="4" className="ion-text-right">{cycleCalculator(user.orders || 0)}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="8">Total Invested</IonCol>
              <IonCol size="4" className="ion-text-right">{currency(user.orderTotal)}</IonCol>
            </IonRow>
          </IonGrid>

          <h5>Transaction Summary</h5>

          <IonGrid>
            {user.transactions && (
              <>
                {user.transactions.map((txn: any) => {
                  return (
                    <IonRow key={`transaction-${uuidv4()}`}>
                      <IonCol size="8">Total {prettyText(txn.type)}</IonCol>
                      <IonCol size="4" className="ion-text-right">{currency(txn.totalAmount)}</IonCol>
                    </IonRow>
                  );
                })}
              </>
            )}
          </IonGrid>
        </div>
      )}
      footer={(
        <div className="ion-padding">
        </div>
      )}
    />
  );
};

export default Account;
