import React, { useContext, useEffect, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent, IonFooter, IonToolbar, IonTitle, IonButton } from '@ionic/react';
import { HeaderOrder, ButtonQty, Button } from 'components';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import { DielContext } from 'core/DielContext';
import { currency } from 'utils/Helper';

interface PackageModalProps {
  pkg?: any;
  onClick?: (pkg: any) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackageModal: React.FC<PackageModalProps> = ({
  pkg,
  onClick = () => null,
  isOpen = false,
  setIsOpen = () => null,
}) => {
  const history = useHistory();
  const { uid } = useAppSelector((states: any) => states.user);
  const [qty, setQty] = useState<number>(1);

  useEffect(() => {
    setQty(1);
  }, [isOpen]);

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => {
        setIsOpen(false);
      }}
    >
      <HeaderOrder
        goBack={() => {
          setIsOpen(false);
        }}
        cartClick={() => {
          setIsOpen(false);
        }}
        title={pkg?.name}
      />
      <IonContent
        fullscreen
      >
        {pkg && (
          <div className="mainWrapper">
            <IonImg src={pkg?.image} />
            <div className="ion-margin-top ion-margin-bottom">{currency(pkg?.price)}</div>
            {/*<div>{pkg?.description}</div>*/}

            <div className="ion-margin-bottom">
              <IonGrid>
                <IonRow>
                  <IonCol size="6">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pkg.items.map((item:any, index: number) => {
                          return (
                            <tr key={`item-${index}`}>
                              <td className="ion-text-center">{item.name}</td>
                              <td className="ion-text-center">{item.quantity}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </IonCol>
                  <IonCol size="6" className="alignRight">
                    <ButtonQty value={qty} onChange={(value: number) => {
                      setQty(value);
                    }}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>

            <Button label="Add to Cart" onClick={() => {
              if (!uid) {
                setIsOpen(false);
                history.push('/login');
              } else {
                onClick({
                  ...pkg,
                  quantity: qty
                });
              }
            }}
            />
          </div>
        )}
      </IonContent>

      {/*<IonFooter>
        <IonToolbar className="footerToolbar">

        </IonToolbar>
      </IonFooter>*/}
    </IonModal>
  );
};

export default PackageModal;
