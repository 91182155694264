import { IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React from 'react';

interface InputProps {
  label?: string;
  icon?: string;
  className?: string;
  placeholder?: string;
  props?: any;
  value?:any;
  onChange?: (e: any) => void;
  options: { key: string; value: string }[];
}

const Select: React.FC<InputProps> = ({
  label,
  // icon,
  placeholder,
  className = '',
  value,
  onChange,
  options = [],
  props = {},
}) => {
  return (
    <div className="inputWrapper">
      {label && (
        <div>{label}</div>
      )}
      <div className={`inputContainer ${className}`}>
        <IonSelect interface="action-sheet" className="input" onIonChange={props.onChange || onChange} placeholder={placeholder} value={props.value || value}>
          {options.map((option: any) => {
            return (
              <IonSelectOption key={`select-option-${option.key}`} value={option.key}>{option.value}</IonSelectOption>
            );
          })}
        </IonSelect>
      </div>
    </div>
  );
};

export default Select;
