import { IonPage, IonContent, IonFooter, IonToolbar, IonTitle } from '@ionic/react';
import React from 'react';
import { Header } from 'components';

interface LayoutCenterProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const LayoutUser: React.FC<LayoutCenterProps> = ({
  children,
  footer,
}) => {
  return (
    <IonPage id="mainContent">
      <Header />

      <IonContent fullscreen>
        <div className="mainWrapper">
          {children}
        </div>
      </IonContent>

      <IonFooter>
        {footer}
      </IonFooter>
    </IonPage>
  );
};

export default LayoutUser;
