import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseService } from 'services';
import { fetcher } from 'utils/Helper';

export const getTransactions = createAsyncThunk('transactions/getTransactions', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;

  const data = await fetcher(`${process.env.REACT_APP_API}/transaction/list`, token, params);

  if (data.code === 100) {
    return {
      count: data.count,
      rows: data.rows,
      page: data.page,
      pages: data.pages,
      limit: data.limit,
    };
  } else {
    return null;
  }
});

const initialState: any = null;

const TransactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: { transactionsReset: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  },
});

export const {
  transactionsReset,
} = TransactionsSlice.actions;

export default TransactionsSlice.reducer;
