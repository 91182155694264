import { LayoutUser } from 'components';
import { IonImg, useIonViewDidEnter } from '@ionic/react';
import diel from 'assets/images/diel.png';
import { DielContext } from 'core/DielContext';
import { useContext } from 'react';

const Deil: React.FC = () => {
  const { setShowTabBar } = useContext(DielContext);

  useIonViewDidEnter(() => {
    setShowTabBar(true);
  }, []);

  return (
    <LayoutUser>
      <IonImg src={diel} style={{ width: '100px' }} />

      <h3>Our Company</h3>

      <p>Creating trust and unique brand identity through  perfumes which are more than just a smell  but have the power to evoke different feelings</p>

      <p>100 % Filipino owned</p>

      <p>Legalities</p>
      <ul>
        <li>DTI Registration</li>
        <li>Business Permit</li>
        <li>BIR Registration</li>
        <li>FDA Certified</li>
      </ul>

    </LayoutUser>
  );
};

export default Deil;
