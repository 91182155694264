import { IonPage, IonContent } from '@ionic/react';
import React from 'react';

interface LayoutCenterProps {
  children: React.ReactNode;
}

const LayoutCenter: React.FC<LayoutCenterProps> = ({
  children
}) => {
  return (
    <IonPage id="authContent">
      <IonContent fullscreen>
        <div className="containerCenter">
          {children}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LayoutCenter;
