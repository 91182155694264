import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseService } from 'services';
import { fetcher } from 'utils/Helper';

export const getMembers = createAsyncThunk('members/getMembers', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;

  console.log('getMembers');
  const data = await fetcher(`${process.env.REACT_APP_API}/user/list`, token, params);

  if (data.code === 100) {
    return {
      count: data.count,
      rows: data.rows,
      page: data.page,
      pages: data.pages,
      limit: data.limit,
    };
  } else {
    return null;
  }
});

export const addMember = createAsyncThunk('members/addMember', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;
  const data = await fetcher(`${process.env.REACT_APP_API}/user/createAccountByUser`, token, params);

  if (data?.code === 100) {
    dispatch(getMembers({ filter: null }));
  }

  return data;
});

const initialState: any = null;

const MembersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: { membersReset: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(getMembers.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  },
});

export const {
  membersReset,
} = MembersSlice.actions;

export default MembersSlice.reducer;
