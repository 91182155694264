import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector, useCookies } from 'hooks';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Splash } from 'components';
import { createUser, setUser, loadUser } from 'reducers/UserSlice';
// import { setAccessToken, loadSettings } from 'reducers/sys/SysSlice';
// import { toibucksCredit } from 'reducers/toibucks/WalletSlice';
import { FirebaseService } from 'services';
// import SplashScreen from 'components/splash/SplashComponent';
// import { DielContext } from 'core/DielContext';
import { telegramLog } from 'utils';

interface AuthProps {
  children?: React.ReactNode;
  state?: any;
}

const Auth: React.FC<AuthProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isNewUser } = useAppSelector((states: any) => states.user);
  // const { setShowTabBar } = useContext(DielContext);

  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  // const { setCookie, getCookie, removeCookie } = useCookies();

  // const autoLoginCheck = () => new Promise((resolve) => {
  //   FirebaseService
  //     .signInWithToken(getCookie('session'), getCookie, removeCookie)
  //     .then((result) => {
  //       if (!result) dispatch(logout({}));
  //       resolve(true);
  //     })
  //     .catch((error) => {

  //       resolve(true);
  //     });

  //   return resolve(true);
  // });

  const firebaseCheck = () => {
    return new Promise((resolve) => {
      FirebaseService.init((success: boolean) => {
        if (!success) {
          resolve({});
        }
      });

      FirebaseService.onAuthStateChanged(async(user: any) => {
        console.log('authUser', user);

        if (user) {
          console.log(user);
          const { uid } = user;

          await dispatch(loadUser(uid));
          resolve(true);
          // FirebaseService.onConnected(uid);

          // await FirebaseService.getUserData(uid)
          //   .then(async(result: any) => {
          //     dispatch(setUser({
          //       ...result.user,
          //       uid,
          //     }));

          //     resolve(true);
          //   },
          //   (error) => {
          //     resolve(true);
          //   },
          //   );
        }
      });

      resolve(true);
    });
  };

  useEffect(() => {
    const initLoad = async () => {
      return Promise.all([ firebaseCheck() ])
        .then((res) => {
          setTimeout(() => {
            setWaitAuthCheck(false);
          }, 3000);
        });
    };

    // console.log('waitAuthCheck', waitAuthCheck);
    if (waitAuthCheck) {
      initLoad();
    }
  }, []);

  return waitAuthCheck && FirebaseService.auth ? (
    <Splash />
  ) :
    <>
      {children}
    </>;
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(Auth);