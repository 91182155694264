import axios from 'axios';
import { FirebaseService } from 'services';

export const currency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
  }).format(amount);
};

export const memberLabel = (type: string) => {
  const labels: any = {
    stockiest: 'Depot',
    depot: 'Distributor',
    distributors: 'Member',
  };

  return labels[type] ?? 'Member';
};

export const telegramLog = (message: string) => {
  const { auth } = FirebaseService;

  if (!auth.currentUser) return;

  axios.post(`${process.env.REACT_APP_API}/telegram/log`, {
    token: auth.currentUser?.accessToken,
    message
  });
};

export const prettyText = (text: string) => {
  const texts: any = {
    infinite: 'Infinite',
    deniaDirectCommission: 'Direct Commission',
    deniaIndirectCommission: 'Indirect Commission',
    deniaReward: 'Reward',
    withdrawalCharge: 'Withdrawal Charge',
    withdrawal: 'Withdrawal',
  };

  return texts[text] || text;
};

export const cycleCalculator = (orders: number) => {
  const count: number = orders - 1;

  if (count < 0) {
    return 9;
  }

  return 8 - (count % 8);
};

export const fetcher = async (
  url: string,
  token: string | undefined,
  params?: any,
  method?: string,
) => {
  const reqInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    const result =
      !method || method === 'post'
        ? await reqInstance.post(url, params || {})
        : await reqInstance.get(url);

    return result.data;
  } catch (error: any) {
    return {
      status: error.response?.status,
      message: error.message,
    };
  }
};
