import { IonButton, IonSpinner, IonIcon, IonRouterLink } from '@ionic/react';
import React from 'react';

interface MenuButtonProps {
  label: string;
  icon:string;
  routerLink?: string;
  className?: string;
  onClick?: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  label = '',
  icon,
  routerLink,
  className = '',
  onClick = () => {},
}) => {
  return (
    <IonRouterLink className={`menuButton ion-justify-center ${className}`} routerLink={routerLink} onClick={onClick}>
      <div><IonIcon src={icon} /></div>
      <div>{label}</div>
    </IonRouterLink>
  );
};

export default MenuButton;
