import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonSpinner, IonIcon, IonInput, IonGrid, IonRow, IonCol } from '@ionic/react';
import { DielContext } from 'core/DielContext';

interface ButtonQtyProps {
  value: number;
  onChange: (val: number) => void;
}

const ButtonQty: React.FC<ButtonQtyProps> = ({
  value = 1,
  onChange = () => null,
}) => {
  const [qty, setQty] = useState<number>(value);

  const updateQty = (val: number) => {
    const newQty = qty + val;
    setQty(newQty <= 0 ? 1 : newQty);
  };

  useEffect(() => {
    onChange(qty);
  }, [qty]);

  return (
    <div className="buttonQtyContainer">
      <IonButton
        className="buttonQtyLeft"
        onClick={() => updateQty(-1)}
      >
        -
      </IonButton>
      <IonInput className="ion-text-center" value={qty} type="text" onIonChange={(e) => {
        const newQty = Number(e.target.value);
        console.log(newQty);
        setQty(() => {
          return newQty <= 0 ? 1 : newQty;
        });
      }} />
      <IonButton
        className="buttonQtyRight"
        onClick={() => updateQty(1)}
      >
        +
      </IonButton>
    </div>
  );
};

export default ButtonQty;
