import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { Carded, Button } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { arrowBack } from 'ionicons/icons';
import { removeItem, checkout } from 'reducers/CartSlice';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { DielContext } from 'core/DielContext';

const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { setShowTabBar } = useContext(DielContext);

  const handleSave = () => {
    // setLoading(true);
    // dispatch(checkout({})).then((action) => {
    //   console.log(action.payload);
    //   setLoading(false);
    // });
  };

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <Carded
      header={(
        <IonToolbar className={'mainHeaderContainer'}>
          <IonButton
            slot="start"
            fill="clear"
            onClick={() => {
              history.goBack();
            }}
          >
            <IonIcon
              className={'headerButton'}
              src={arrowBack}
            />
          </IonButton>
          <IonTitle
            slot="start"
          >
            Settings
          </IonTitle>
        </IonToolbar>
      )}
      content={(
        <>
          Todo
        </>
      )}
      footer={(
        <>

        </>
      )}
    />
  );
};

export default Settings;
