import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import React from 'react';
import { currency } from 'utils/Helper';

interface ItemsProps {
  title: string;
  data: any;
  onClick?: (pkg: any) => void;
}

const Packages: React.FC<ItemsProps> = ({
  title = '',
  data = null,
  onClick = () => null,
}) => {
  const items = (Object.entries(data) || []).filter((item: any) => item[1].status === 'active');

  return (
    <>
      <h3>{title}</h3>

      <IonGrid>
        <IonRow className="">
          {items.map((item: any) => {
            const [key, val] = item;
            const pkg = {
              ...val,
              key,
            };

            return (
              <IonCol key={key} size="4" className="packages ion-text-center ion-margin-bottom" onClick={() => {
                onClick(pkg);
              }}
              >
                <IonImg src={pkg.image} style={{ height: '80px' }} />
                <div className="itemTitle">{pkg.name}</div>
                <div className="packagePrice">{currency(pkg.price)}</div>
                {/*
                <div className="packageItems">
                  {pkg.items.map((item: any, index: number) => {
                    return (
                      <div key={`${pkg.key}-${index}`}>
                        {item.name} {item.quantity}
                      </div>
                    );
                  })}
                </div>
                */}
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default Packages;
