import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { getDatabase, child, ref, get, update } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import config from 'firebase.config.json';

class FirebaseService {
  auth: any;
  db: any;
  storage: any;

  init(success: any) {
    initializeApp(config);
    this.auth = getAuth();
    this.db = getDatabase();
    this.storage = getStorage();

    success(true);
  }

  getUserData = (uid: any): Promise<any> => {
    const db = getDatabase();

    return new Promise<any>((resolve, reject) => {
      get(child(ref(db), `users/${uid}`)).then(async(snapshot) => {
        if (snapshot.exists()) {
          const user = snapshot.val();
          resolve({ user });
        } else {
          resolve({ user: null });

          reject('No data available');
        }
      }).catch((error) => {
        reject(error);
      });
    });
  };

  onAuthStateChanged = (callback: any) => {
    console.log('onAuthStateChanged');
    const auth = getAuth();

    onAuthStateChanged(auth, callback);
  };

  // signInWithToken = async(session: any, getCookie: any, removeCookie: any) => {
  //   if (!session) return null;

  //   // validate session
  //   const response = await axios.post(`${process.env.REACT_APP_FANTASY_API_URL}/system/verifysession`, { toiboxToken: session });

  //   const { data } = response;

  //   if (data.code === 100) {
  //     signInWithCustomToken(this.auth, data.newToken);
  //     return true;
  //   } else {
  //     removeCookie('session');
  //     return false;
  //   }
  // };

  // onConnected = (uid: string) => {
  //   const db = getDatabase();

  //   const dbRef = ref(db, '.info/connected');
  //   const userStatusDatabaseRef = ref(db, `/users/${uid}`);

  //   let isOfflineForDatabase = { status: 'offline' };

  //   let isOnlineForDatabase = { status: 'online' };

  //   onValue(dbRef, (snapshot) => {
  //     const data = snapshot.val();

  //     if (!data) return;

  //     onDisconnect(userStatusDatabaseRef)
  //       .update(isOfflineForDatabase)
  //       .then(() => {
  //         update(userStatusDatabaseRef, isOnlineForDatabase);
  //       });
  //   });

  // };

  // signOut = () => {
  //   const auth = getAuth();
  //   const db = getDatabase();

  //   if (!auth) return;

  //   const uid = auth.currentUser?.uid;
  //   update(ref(db, `/users/${uid}`), { status: 'offline' });

  //   auth.signOut();
  // };
}

const instance = new FirebaseService();

export default instance;
