import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton, IonImg, IonTitle } from '@ionic/react';
import { menu, person, arrowBack, cart } from 'ionicons/icons';
import { useAppSelector } from 'hooks';

interface HeaderOrderProps {
  collapse?: any;
  size?: string;
  title?: string;
  goBack: () => void;
  cartClick?: () => void;
}

const HeaderOrder: React.FC<HeaderOrderProps> = ({
  collapse,
  size,
  title,
  goBack = () => null,
  cartClick = () => null,
}) => {
  const cartItems = useAppSelector((state) => state.cart);

  return (
    <IonHeader collapse={collapse}>
      <IonToolbar
        className={'mainHeaderContainer'}
      >
        <IonButtons
          slot={'start'}
        >
          <IonButton
            onClick={goBack}
          >
            <IonIcon
              className={'headerButton'}
              src={arrowBack}
            />
          </IonButton>
        </IonButtons>
        <span
          slot="start"
          className="title"
        >
          {title}
        </span>

        <IonButtons
          slot={'end'}
        >
          <IonButton
            routerLink={'/cart'}
            onClick={cartClick}
          >
            <IonIcon
              className={'headerButton'}
              src={cart}
            />
            {cartItems.items.length}
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderOrder;


