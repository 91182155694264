import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { uuidv4 } from '@firebase/util';
import { telegramLog } from 'utils';
import { FirebaseService } from 'services';
import { fetcher } from 'utils/Helper';

export const checkout = createAsyncThunk('cart/checkout', async (params: any, { dispatch, getState } : any) => {
  try {
    const { auth, storage } = FirebaseService;
    const { receipt } = params;
    const { items, total } = getState().cart;
    const token = auth.currentUser?.accessToken;

    /* upload receipt */
    const extension = receipt.name.split('.').pop();
    const receiptRef = storageRef(storage, `/receipts/${uuidv4()}.${extension}`);
    await uploadBytes(receiptRef, receipt);

    const url = await getDownloadURL(receiptRef);

    const data = await fetcher(`${process.env.REACT_APP_API}/order/create`, token, {
      total,
      receipt: url,
      items,
    });

    if (data.code === 200) {
      throw new Error('Something went wrong');
    }

    dispatch(cartReset());

    telegramLog('Order created: ❤️');

    return true;
  } catch (e) {
    return false;
  }
});

const initialState: any = {
  items: [],
  total: 0,
};

const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    cartReset: () => initialState,
    addCart: {
      reducer: (state, action: any) => {
        const pkgIndex: number = state.items.findIndex((item: any) => item.key === action.payload.key);

        if (pkgIndex >= 0) {
          state.items[pkgIndex].quantity = action.payload.quantity;
        } else {
          state.items = [...state.items, action.payload];
          // return [...state, ];
        }

        let total = 0;

        state.items.forEach((item: any) => {
          total += item.price * item.quantity;
        });

        state.total = total;
      },
      prepare: (params) => ({ payload: params }),
    },
    removeItem: {
      reducer: (state, action: any) => {
        const pkgIndex: number = state.items.findIndex((item: any) => item.key === action.payload);

        if (pkgIndex >= 0) {
          state.items.splice(pkgIndex, 1);

          let total = 0;

          state.items.forEach((item: any) => {
            total += item.price * item.quantity;
          });

          state.total = total;
        }
      },
      prepare: (params) => ({ payload: params }),
    },
  },
  extraReducers: (builder) => {
    // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // builder.addCase(setUserDataFirebase.fulfilled, (state, action: any) => {
    //   state.user.email = action.payload.email;
    //   state.user.uid = action.payload.uid;
    //   state.user.displayName = action.payload.displayName;
    //   state.loggedIn = action.payload.loggedIn;
    // });
  },
});

export const {
  cartReset,
  addCart,
  removeItem,
} = CartSlice.actions;

export default CartSlice.reducer;