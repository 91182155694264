import { combineReducers } from 'redux';
import user from './UserSlice';
import cart from './CartSlice';
import orders from './OrdersSlice';
import members from './MembersSlice';
import withdrawals from './WithdrawalsSlice';
import transactions from './TransactionsSlice';

const rootReducer: any = combineReducers({
  user,
  cart,
  orders,
  members,
  withdrawals,
  transactions,
});

export default rootReducer ;

