import { Redirect, Route } from 'react-router';
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, } from '@ionic/react';
import { home, person, list } from 'ionicons/icons';

import Login from 'pages/auth/Login';
import Register from 'pages/auth/Register';
import Home from 'pages/Home';
import Diel from 'pages/Diel';
import Mission from 'pages/Mission';
import Vision from 'pages/Vision';
import About from 'pages/About';
import Packages from 'pages/Packages';
import { DielContext } from 'core/DielContext';
import { useContext } from 'react';

const AuthRoute: React.FC = () => {
  const { showTabBar } = useContext(DielContext);

  return (
    <IonRouterOutlet>
      {/*<Route exact path="/home"><Home /></Route>*/}
      {/*<Route exact path="/packages"><Packages /></Route>
      <Route exact path="/diel"><Diel /></Route>
      <Route exact path="/mission"><Mission /></Route>
      <Route exact path="/vision"><Vision /></Route>
      <Route exact path="/about"><About /></Route>*/}
      <Route exact path="/login"><Login /></Route>
      <Route exact path="/register"><Register /></Route>
      <Route render={() => <Redirect to="/login" />} />
    </IonRouterOutlet>
  );
};

export default AuthRoute;
