import React, { useContext } from 'react';
import { IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent } from '@ionic/react';
import { Header } from 'components';
import moment from 'moment';
import { currency } from 'utils/Helper';
import { DielContext } from 'core/DielContext';

interface OrderModalProps {
  order?: any;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderModal: React.FC<OrderModalProps> = ({
  order,
  isOpen = false,
  setIsOpen = () => null,
}) => {
  const { packages } = useContext(DielContext);

  return (
    <IonModal
      isOpen={isOpen}
      onWillDismiss={() => {
        setIsOpen(false);
      }}
    >
      <Header
        goBack={() => {
          setIsOpen(false);
        }}
        title="Order Details"
        showGoBack
        showCart={false}
        showMenu={false}
      />
      <IonContent
        fullscreen
      >
        {order && (
          <div className="mainWrapper">
            <div>Key: {order?.id}</div>
            <div>Created At: {moment(order.createdAt).format('MM/DD/YYYY h:mm a')}</div>
            <div>Status: {order?.status}</div>
            <div>Total: {currency(order?.total)}</div>
            <div className="ion-margin-bottom">
              <a
                href={order.receipt}
                target="_blank"
                rel="noreferrer"
              >
                Reciept
              </a>
            </div>

            <div className="ion-margin-bottom">
              <div>Items</div>

              <IonGrid>
                {order.txns?.map((item: any, index: number) => {
                  const category = item.category.toLowerCase();
                  const pkg = packages[category][item.itemKey];

                  return (
                    <IonRow key={`order-item-${index}`}>
                      <IonCol>
                        <IonImg src={pkg.image} style={{ width: '50px' }} />
                      </IonCol>
                      <IonCol>
                        <div>{pkg.category} {pkg.name}</div>
                        <div>{currency(item.price)}</div>
                        <div>{item.quantity}</div>
                      </IonCol>
                    </IonRow>
                  );
                })}
              </IonGrid>
            </div>
          </div>
        )}
      </IonContent>

      {/*<IonFooter>
        <IonToolbar className="footerToolbar">

        </IonToolbar>
      </IonFooter>*/}
    </IonModal>
  );
};

export default OrderModal;
