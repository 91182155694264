import { useEffect, useState } from 'react';
import { FirebaseService } from 'services';

export const useApp = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, [FirebaseService.auth]);

  return { loaded };
};