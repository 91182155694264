import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, getAdditionalUserInfo, signInWithEmailAndPassword } from 'firebase/auth';
import { LayoutCenter, Input, Button } from 'components';
import { logoFacebook, logoGoogle, logIn, key, mail } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch, useHelper } from 'hooks';
import { FirebaseService } from 'services';
import { useEffect, useState, useContext } from 'react';
import { DielContext } from 'core/DielContext';
import { telegramLog } from 'utils';
import { IonImg } from '@ionic/react';
import diel from 'assets/images/diel.png';

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required().email(),
});

const defaultValues = {
  email: '',
  password: '',
};

const Login: React.FC = () => {
  const { setShowTabBar } = useContext(DielContext);
  const dispatch = useAppDispatch();
  const { showToast } = useHelper();
  const { auth } = FirebaseService;
  const [loading, setLoading] = useState(false);
  const { control, formState, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields } = formState;
  const form = watch();

  const handleEmailLogin = () => {
    setLoading(true);
    signInWithEmailAndPassword(FirebaseService.auth, form.email, form.password)
      .then(async(result: any) => {
        telegramLog(`Logged in: ${result._tokenResponse.email}`);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('error.message', error.message);
        showToast({
          message: error.message
        });
      });
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result: any) => {
        const details = getAdditionalUserInfo(result);

        // dispatch(createUser({
        //   uid
        // }))

        // dispatch(setShowLoginModal(false));
        // dispatch(setShowSignupModal(false));
        // dispatch(setIsNewUser(details?.isNewUser));
        // callback(true);
      }).catch(async(error) => {
        // callback(false);
        // dispatch(showToast({
        //   message: error.message,
        //   color: 'danger',
        // }));
      });
  };

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  // const handleFacebookLogin = (callback: (success: boolean) => void) => {
  //   const provider = new FacebookAuthProvider();

  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       const details = getAdditionalUserInfo(result);
  //       dispatch(setShowLoginModal(false));
  //       dispatch(setShowSignupModal(false));
  //       dispatch(setIsNewUser(details?.isNewUser));

  //       callback(true);
  //     }).catch(async(error) => {
  //       callback(false);
  //       dispatch(showToast({
  //         message: error.message,
  //         color: 'danger',
  //       }));
  //     });
  // };

  return (
    <LayoutCenter>
      <div className="itemsCenter mb20">
        <IonImg src={diel} style={{ width: '200px' }} />
      </div>

      {/*<div className="mb20">
        <Button label="Login with Google" props={{ expand: 'full' }} icon={logoGoogle} onClick={handleGoogleLogin} />

        <Button label="Login with Facebook" props={{ expand: 'full' }} icon={logoFacebook} onClick={
          () => {
            console.log('Save');
          }}
        />
      </div>*/}

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            label="Email address"
            icon={mail}
            placeholder="Enter email address"
            props={field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <Input
            label="Password"
            icon={key}
            placeholder="Enter password"
            props={{
              ...field,
              type: 'password'
            }}
          />
        )}
      />

      <Button loading={loading} disabled={loading} label="Login" props={{ expand: 'block' }} icon={logIn} onClick={handleEmailLogin} />

      <Button label="Register"
        props={{
          expand: 'block',
          color: 'medium',
          routerLink: '/register',
        }}
      />

      {/*<Button label="Back" color="medium" props={{
        expand: 'block',
        routerLink: '/',
        fill: 'clear',
      }}
      />*/}

    </LayoutCenter>
  );
};

export default Login;
