// import '@fake-db';
import {
  IonApp,
  setupIonicReact,
  IonRouterOutlet,
} from '@ionic/react';
import { Route } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import { AuthRoute, UserRoute, OnboardingRoute } from 'routes';
import { DielProvider, DielContext } from 'core/DielContext';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import { Auth, Menu, Splash } from 'components';
import { useApp, useAppSelector } from 'hooks';
/* Theme variables */
import './theme/variables.css';
import './theme/global.css';
import { useContext, useEffect } from 'react';

setupIonicReact();

const App: React.FC = () => {
  const { uid, status } = useAppSelector((states: any) => states.user);
  const { user } = useAppSelector((states: any) => states);
  const { loaded } = useApp();

  useEffect(() => {
    console.log('user', user);
  }, [user]);

  return (
    <IonApp>
      <Auth>
        <DielProvider>
          <IonReactRouter>
            <Menu />
            {loaded && (
              <>
                { uid ? (
                  <>
                    {status === 'incomplete' ? <OnboardingRoute /> : <UserRoute />}
                  </>
                ) : <AuthRoute /> }
              </>
            )}
          </IonReactRouter>
        </DielProvider>
      </Auth>
    </IonApp>
  );
};

export default App;
