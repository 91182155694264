import { Redirect, Route } from 'react-router';
import { IonRouterOutlet } from '@ionic/react';

import PersonalDetails from 'pages/onboarding/PersonalDetails';
import Address from 'pages/onboarding/Address';
import Contact from 'pages/onboarding/Contact';

// import { useContext } from 'react';
// import { DielContext } from 'core/DielContext';
// import { useAppSelector } from 'hooks';

const OnboardingRoute: React.FC = () => {
  // const {type} = useAppSelector((state: any) => state.user);
  // const {showTabBar} = useContext(DielContext);

  return (
    <IonRouterOutlet>
      <Route path="/personalDetails"><PersonalDetails /></Route>
      <Route path="/address"><Address /></Route>
      <Route path="/contact"><Contact /></Route>
      <Route render={() => <Redirect to="/personalDetails" />} />
    </IonRouterOutlet>
  );
};

export default OnboardingRoute;
