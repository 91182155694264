import { IonPage, IonContent, IonFooter, IonToolbar, IonTitle, IonHeader } from '@ionic/react';
import React from 'react';
import { Header } from 'components';

interface LayoutCenterProps {
  header?: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
}

const Carded: React.FC<LayoutCenterProps> = ({
  header,
  content,
  footer,
}) => {
  return (
    <IonPage id="mainContent">
      <IonHeader>
        {header}
      </IonHeader>

      <IonContent fullscreen>
        <div className="mainWrapper">
          {content}
        </div>
      </IonContent>

      <IonFooter className="cardedFooter">
        {footer}
      </IonFooter>
    </IonPage>
  );
};

export default Carded;
