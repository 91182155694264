import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { LayoutCenter, Input, Button, Select } from 'components';
import { logoFacebook, logoGoogle, logIn, key, mail } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch } from 'hooks';
import { FirebaseService } from 'services';
import { useEffect, useState, useContext } from 'react';
import { DielContext } from 'core/DielContext';
import { telegramLog } from 'utils';
import { IonImg, useIonViewDidEnter } from '@ionic/react';
import diel from 'assets/images/diel.png';
import { createUser } from 'reducers/UserSlice';

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required().email(),
  type: yup.string().required(),
});

const defaultValues = {
  email: '',
  password: '',
  type: null,
};

const accountOptions = [{
  key: 'stockiest',
  value: 'Stockiest',
},
{
  key: 'depot',
  value: 'Depot',
},
{
  key: 'distributor',
  value: 'Distributor',
},
{
  key: 'member',
  value: 'Member',
}];

const Register: React.FC = () => {
  const { setShowTabBar, showToast } = useContext(DielContext);
  const dispatch = useAppDispatch();
  const { auth } = FirebaseService;
  const [loading, setLoading] = useState(false);
  const { control, formState, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields } = formState;
  const form = watch();

  const handleRegister = () => {
    setLoading(true);

    console.log('form', form);

    createUserWithEmailAndPassword(FirebaseService.auth, form.email, form.password)
      .then(async(result: any) => {
        const { user } = result;
        telegramLog(`register: ${user.email}`);

        console.log('result', result);
        dispatch(createUser({
          token: result.user.accessToken,
          params: form,
        })).then(() => {
          signInWithEmailAndPassword(FirebaseService.auth, form.email, form.password);
          setLoading(false);
        });

      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
      });
  };

  useIonViewDidEnter(() => {
    setShowTabBar(false);
  });

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <LayoutCenter>
      <div className="itemsCenter mb20">
        <IonImg src={diel} style={{ width: '200px' }} />
      </div>

      <div className="">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              label="Email Address"
              icon={mail}
              placeholder="Enter email address"
              props={field}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              label="Password"
              icon={key}
              placeholder="Enter password"
              props={{
                ...field,
                type: 'password'
              }}
            />
          )}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select label="Account Type" props={field} placeholder="Account Type" options={accountOptions} />
          )}
        />
      </div>

      <Button loading={loading} disabled={loading} label="Register" props={{ expand: 'block' }} onClick={handleRegister} />

      <Button label="Back" color="medium" props={{
        expand: 'block',
        routerLink: '/login',
        fill: 'clear',
      }}
      />

    </LayoutCenter>
  );
};

export default Register;
