import { useEffect, useState, useContext } from 'react';
import { ExploreContainer, Carded, Header, MenuButton } from 'components';
import { DielContext } from 'core/DielContext';
import { IonToolbar, IonButton, IonIcon, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/react';
import { arrowBack, list, arrowForward, car, paperPlane, send, wallet, checkmark, close, trash, person, settings, listOutline, sendOutline, logOut } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { memberLabel } from 'utils/Helper';
import { useAppSelector } from 'hooks';

const MembersOrders: React.FC = () => {
  const history = useHistory();
  const { type } = useAppSelector((state: any) => state.user);
  const { setShowTabBar } = useContext(DielContext);

  useEffect(() => {
    setShowTabBar(false);

    if (type === 'member') {
      history.push('/account');
    }

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <Carded
      header={
        <Header
          title="Members Orders"
          showGoBack
          showCart={false}
          showMenu={false}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      content={(
        <>
        </>
      )}
    />
  );
};

export default MembersOrders;
