import { useEffect, useState, useContext } from 'react';
import { LayoutUser, Packages as Pkgs, PackageModal } from 'components';
import { addCart } from 'reducers/CartSlice';
import { useAppDispatch } from 'hooks';
import { DielContext } from 'core/DielContext';
import { useIonViewDidEnter } from '@ionic/react';

const Packages: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [item, setItem] = useState<any>();
  const { setShowTabBar, packages, showToast } = useContext(DielContext);

  const handleOnClick = (pkg: any) => {
    setItem(pkg);
    setIsPackageModalOpen(true);
  };

  useIonViewDidEnter(() => {
    setShowTabBar(true);
  });

  return (
    <LayoutUser>
      {packages && (
        <>
          <Pkgs title="Denia" data={packages.denia} onClick={handleOnClick} />
          {/*
            <Pkgs title="Monie" data={packages.monie} onClick={handleOnClick} />
          */}

          <PackageModal
            isOpen={isPackageModalOpen}
            setIsOpen={setIsPackageModalOpen}
            pkg={item}
            onClick={(item) => {
              dispatch(addCart(item));
              setIsPackageModalOpen(false);
              showToast('Successfully added', 'bottom');
            }}
          />
        </>
      )}
    </LayoutUser>
  );
};

export default Packages;
