import { useEffect, useState, useContext } from 'react';
import { Carded, Header, Input, Button, Select } from 'components';
import { DielContext } from 'core/DielContext';
import { IonFooter, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FirebaseService } from 'services';
import moment from 'moment';
import { addMember } from 'reducers/MembersSlice';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  type: yup.string().required(),
}).required();

const defaultValues = {
  email: '',
  password: '',
  type: '',
};

const MemberForm: React.FC = () => {
  const { currentUser } = FirebaseService.auth;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { type: accountType } = useAppSelector((state: any) => state.user);
  const { setShowTabBar, showToast } = useContext(DielContext);
  const [loading, setLoading] = useState(false);

  let accountOptions = [{
    key: 'depot',
    value: 'Depot',
  },
  {
    key: 'distributor',
    value: 'Distributor',
  },
  {
    key: 'member',
    value: 'Member',
  }];
  let defaultAccountOption = 'depot';

  if (accountType === 'depot') {
    defaultAccountOption = 'distributor';
    accountOptions = accountOptions.filter((option: any) => option.key === 'distributor' || option.key === 'member');
  } else if (accountType === 'distributor') {
    defaultAccountOption = 'member';
    accountOptions = accountOptions.filter((option: any) => option.key === 'member');
  }

  const { control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      type: defaultAccountOption
    },
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const form = watch();

  const handleSave = async() => {
    setLoading(true);
    dispatch(addMember({
      token: currentUser.accessToken,
      ...form,
      parentUid: currentUser?.uid,
      createdAt: moment().format(),
    }))
      .then((action: any) => {
        console.log('action', action.payload);
        setLoading(false);
        const data = action.payload;

        if (data?.code === 100) {
          showToast('Successfully added');
          history.push('/members');
        } else if (data?.code === 200) {
          showToast(data?.message);
        }
      });
  };

  useIonViewDidLeave(() => {
    setShowTabBar(true);
  });

  useIonViewWillEnter(() => {
    setShowTabBar(false);
    if (accountType === 'member') {
      history.push('/account');
    }
  }, []);

  // useEffect(() => {
  //   setShowTabBar(false);
  //   if (accountType === 'member') {
  //     history.push('/account');
  //   }

  //   return (() => {
  //     setShowTabBar(true);
  //   });
  // }, []);

  return (
    <Carded
      header={
        <Header
          title="Add Member"
          showGoBack
          showCart={false}
          showMenu={false}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      content={(
        <div>
          <div className="mainWrapper">
            <h5>Access</h5>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div>
                  {/*<div className="formLabel">Email</div>*/}
                  <Input
                    props={field}
                    placeholder="Email"
                  />
                </div>
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <div>
                  {/*<div className="formLabel">Password</div>*/}
                  <Input
                    props={field}
                    placeholder="Password"
                  />
                </div>
              )}
            />

            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <div>
                  <Select props={field} placeholder="Account Type" options={accountOptions} />
                </div>
              )}
            />

            {/*
              <h5>Personal Details</h5>

              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="First Name"
                  />
                )}
              />

              <Controller
                name="lastname"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Last Name"
                  />
                )}
              />

              <Controller
                name="middlename"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Middle Name"
                  />
                )}
              />

              <Controller
                name="birthdate"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Birth Date"
                  />
                )}
              />

              <h5>Contact</h5>

              <Controller
                name="mobile"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Mobile"
                  />
                )}
              />

              <h5>Address</h5>

              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Address"
                  />
                )}
              />

              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="City"
                  />
                )}
              />

              <Controller
                name="province"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Province"
                  />
                )}
              />

              <Controller
                name="zip"
                control={control}
                render={({ field }) => (
                  <Input
                    props={field}
                    placeholder="Zip Code"
                  />
                )}
              />
            */}

            <IonFooter>
              <Button
                props={{ expand: 'block' }}
                label="Save"
                disabled={!isValid || loading}
                loading={loading}
                onClick={handleSave}
              />
            </IonFooter>
          </div>
        </div>
      )}
    />
  );
};

export default MemberForm;
