import { useIonToast } from '@ionic/react';

export const useHelper = () => {
  const [present] = useIonToast();

  const showToast = ({ message, duration = 1500, position = 'top' } : { message: string; duration?: number; position?: 'top' | 'middle' | 'bottom' }) => {
    present({
      message,
      duration,
      position
    });
  };

  return { showToast };
};