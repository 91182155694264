import { useEffect, useState, useContext } from 'react';
import { ExploreContainer, Carded, Header, Input, Button, MenuButton } from 'components';
import { DielContext } from 'core/DielContext';
import { IonToolbar, IonButton, IonIcon, IonTitle, IonGrid, IonRow, IonCol, IonImg, IonSpinner, useIonAlert, IonContent, IonTabs, IonTabBar, IonTabButton, IonTab, IonRouterOutlet, useIonViewWillEnter, useIonViewWillLeave, useIonViewDidLeave, IonList, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, list, arrowForward, car, paperPlane, send, wallet, checkmark, close, trash } from 'ionicons/icons';
import { RouteComponentProps, useHistory } from 'react-router';
import { getWithdrawals, sendRequest } from 'reducers/WithdrawalsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import moment from 'moment';
import { currency } from 'utils/Helper';
import { uuidv4 } from '@firebase/util';

interface WithdrawalProps extends RouteComponentProps {
  match: any;
}

const Withdrawals: React.FC<WithdrawalProps> = ({ match }) => {
  const { balance, onholdBalance } = useAppSelector((states: any) => states.user);
  const withdrawals = useAppSelector((states: any) => states.withdrawals);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setShowTabBar, showToast } = useContext(DielContext);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<string>('');
  const [presentAlert] = useIonAlert();
  const status = match.params.status || 'pending';

  const generateWithdrawals = async (page = 0) => {
    dispatch(getWithdrawals({
      status,
      page
    })).then((action: any) => {
      const result = action.payload;

      if (result) {
        if (page === 0) {
          setData(result.rows);
        } else {
          setData([...data, ...result.rows]);
        }
      }
    });
  };

  const handleScroll = (e: any) => {
    if (withdrawals.page + 1 < withdrawals.pages) {
      generateWithdrawals(withdrawals.page + 1);
    }
    setTimeout(() => e.target.complete(), 500);
  };

  useIonViewDidLeave(() => {
    setShowTabBar(true);
    setData([]);
  });

  useIonViewDidEnter(() => {
    console.log('status', status);
    generateWithdrawals();
  }, [status]);

  return (
    <Carded
      header={(
        <Header title="Withdrawals" showGoBack showCart={false} showMenu={false} goBack={() => {
          history.push('/account');
        }}
        />
      )}
      content={(
        <>
          <div className="ion-margin-top">
            <IonGrid>
              <IonRow className="ion-text-center">
                <IonCol className="">{currency(balance)} <div className="title">Balance</div></IonCol>
                <IonCol className="">{currency(onholdBalance || 0)}<div className="title">On Hold</div></IonCol>
              </IonRow>
            </IonGrid>

            <IonGrid>
              <IonRow>
                <IonCol size="8">
                  <Input value={amount} placeholder="Enter amount" onChange={(e) => {
                    setAmount(e.target.value ?? '');
                  }}
                  />
                </IonCol>
                <IonCol size="4">
                  <Button label="Submit" onClick={() => {
                    const _amount = parseFloat(amount) || 0;

                    if (_amount < 100) {
                      showToast(`Minimum amount is ${currency(100)}`);
                      return;
                    }

                    if (_amount > balance) {
                      showToast('Insufficient balance');
                      return;
                    }

                    presentAlert({
                      message: `You are reqesting ${currency(_amount)}. Do you want to continue?`,
                      buttons: [{
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {},
                      },
                      {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => {
                          dispatch(sendRequest({
                            amount: _amount,
                          })).then((action: any) => {
                            if (action.payload) {
                              setAmount('');
                            } else {
                              showToast('Something went wrong.');
                            }
                          });
                        },
                      }],
                    });
                  }}
                  />
                </IonCol>
              </IonRow>
              <div className="textSmall">There will be {currency(50)} every successful withdrawal request.</div>
            </IonGrid>

            {/*
            <IonTabs>
              <IonTabBar>
                <IonTabButton tab="pending">
                  <div><IonIcon src={wallet} /></div>
                  <div>Pending</div>
                </IonTabButton>
                <IonTabButton tab="completed">
                  <div><IonIcon src={checkmark} /></div>
                  <div>Completed</div>
                </IonTabButton>
                <IonTabButton tab="cancelled">
                  <div><IonIcon src={trash} /></div>
                  <div>Cancelled</div>
                </IonTabButton>
              </IonTabBar>

              <IonRouterOutlet>

              </IonRouterOutlet>
            </IonTabs>
            */}

            <IonGrid className="ion-margin-bottom">
              <IonRow>
                <IonCol size="4" className="ion-justify-center">
                  <MenuButton routerLink="/withdrawals/pending" className={status === 'pending' ? 'active' : ''} label="Pending" icon={wallet} />
                </IonCol>
                <IonCol size="4" className="ion-justify-center">
                  <MenuButton routerLink="/withdrawals/approved" className={status === 'approved' ? 'active' : ''} label="Approved" icon={checkmark} />
                </IonCol>
                <IonCol size="4" className="ion-justify-center">
                  <MenuButton routerLink="/withdrawals/rejected" className={status === 'rejected' ? 'active' : ''} label="Rejected" icon={trash} />
                </IonCol>
              </IonRow>
            </IonGrid>

            {loading ? (
              <>
                <IonSpinner
                  name={'lines-sharp'}
                  color={'#ccc'}
                >
                </IonSpinner>
              </>
            ) : (
              <>
                <div className="ion-margin-top">
                  {data.length === 0 ? (
                    <div>No withdrawals found</div>
                  ) : (
                    <>
                      <IonList>
                        {data.map((item: any) => {
                          return (
                            <div key={`order-${item.id}-${uuidv4()}`} className="orderContainer">
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    <div className="textSmall">{moment(item.createdAt).format('MM/DD/YYYY h:mm a')}</div>
                                    <div>{item.status}</div>
                                  </IonCol>
                                  <IonCol className="ion-text-right">
                                    <div>{currency(item.amount)}</div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </div>
                          );
                        })}
                      </IonList>

                      <IonInfiniteScroll
                        onIonInfinite={handleScroll}
                      >
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                      </IonInfiniteScroll>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    />
  );
};

export default Withdrawals;
