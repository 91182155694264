import React, { useContext, useEffect, useState, createRef } from 'react';
import { IonButton, IonSpinner, IonIcon, IonInput, IonGrid, IonRow, IonCol } from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';

interface UploadFileProps {
  value?: any;
  onChange: (file: any) => void;
}

const UploadFile: React.FC<UploadFileProps> = ({
  value,
  onChange = () => null,
}) => {
  const fileInput: any = createRef();
  const [file, setFile] = useState<any>(value);

  return (
    <div className="">
      <input
        accept="image/*"
        className="ion-hide"
        multiple
        type="file"
        onChange={(e: any) => {
          setFile(e.target.files[0]);
          onChange(e.target.files[0]);
        }}
        ref={fileInput}
      />
      <IonButton onClick={() => {
        fileInput.current.click();
      }}
      >
        Upload <IonIcon src={cloudUpload} />
      </IonButton>

      {file && (
        <div>{file.name}</div>
      )}
    </div>
  );
};

export default UploadFile;
