import { useEffect, useState, useContext } from 'react';
import { ExploreContainer, Carded, Header, Input, Button, MenuButton } from 'components';
import { DielContext } from 'core/DielContext';
import { IonToolbar, IonButton, IonIcon, IonTitle, IonGrid, IonRow, IonCol, IonImg, IonSpinner, useIonAlert, IonContent, IonTabs, IonTabBar, IonTabButton, IonTab, IonRouterOutlet, useIonViewWillEnter, useIonViewWillLeave, useIonViewDidLeave, IonList, IonInfiniteScroll, IonInfiniteScrollContent, useIonViewDidEnter } from '@ionic/react';
import { arrowBack, list, arrowForward, car, paperPlane, send, wallet, checkmark, close, trash, person } from 'ionicons/icons';
import { RouteComponentProps, useHistory } from 'react-router';
import { getMembers } from 'reducers/MembersSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import moment from 'moment';
import { currency } from 'utils/Helper';
import { uuidv4 } from '@firebase/util';

interface MemberProps {
  match?: any;
}

const Members: React.FC<MemberProps> = ({ match }) => {
  console.log('Members');
  const dispatch = useAppDispatch();
  const members = useAppSelector((states: any) => states.members);
  const history = useHistory();
  const { setShowTabBar, showToast } = useContext(DielContext);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  // const status = match.params.status ?? 'pending';

  const generateMembers = async (page = 0) => {
    dispatch(getMembers({
      status,
      page
    })).then((action: any) => {
      const result = action.payload;

      if (result) {
        if (page === 0) {
          setData(result.rows);
        } else {
          setData([...data, ...result.rows]);
        }
      }
    });
  };

  const handleScroll = (e: any) => {
    if (members.page + 1 < members.pages) {
      generateMembers(members.page + 1);
    }
    setTimeout(() => e.target.complete(), 500);
  };

  useIonViewDidLeave(() => {
    // setShowTabBar(true);
    setData([]);
  });

  useIonViewDidEnter(() => {
    console.log('useIonViewDidEnter');
    generateMembers();
  }, []);

  // useEffect(() => {
  //   console.log('useEffect');
  //   generateMembers();

  //   return (() => {
  //   });
  // }, []);

  return (
    <Carded
      header={(
        <Header title="Members" showGoBack showCart={false} showMenu={false} goBack={() => {
          history.push('/account');
        }}
        />
      )}
      content={(
        <>
          <div className="ion-margin-top">
            <IonGrid className="ion-margin-bottom">
              <IonRow>
                <IonCol size="3" className="ion-justify-center">
                  <MenuButton routerLink="/member/add" label="Add Member" icon={person} />
                </IonCol>
                <IonCol size="3" className="ion-justify-center">
                  <MenuButton routerLink="/members/orders" label="Orders" icon={list} />
                </IonCol>
              </IonRow>
            </IonGrid>

            {loading ? (
              <>
                <IonSpinner
                  name={'lines-sharp'}
                  color={'#ccc'}
                >
                </IonSpinner>
              </>
            ) : (
              <>
                <div className="ion-margin-top">
                  {data.length === 0 ? (
                    <div>No members found</div>
                  ) : (
                    <>
                      <IonList>
                        {data.map((member: any) => {
                          return (
                            <div key={`member-${member.id}-${uuidv4()}`} className="orderContainer">
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    <div>{member.email}</div>
                                    {member.lastname && (
                                      <div className="textSmall">
                                        {`${member.lastname}, ${member.firstname} ${member.middlename}`}
                                      </div>
                                    )}
                                    {member.mobile && (
                                      <div className="textSmall">{member.mobile}</div>
                                    )}
                                  </IonCol>
                                  <IonCol class="ion-text-right">
                                    <div>{member.type}</div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </div>
                          );
                        })}
                      </IonList>

                      <IonInfiniteScroll
                        onIonInfinite={handleScroll}
                      >
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                      </IonInfiniteScroll>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    />
  );
};

export default Members;
