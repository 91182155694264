import { useLayoutEffect } from 'react';
import Cookies, { CookieChangeListener } from 'universal-cookie';

// COOKIES
const globalCookies = new Cookies();

export const useCookies = () => {

  useLayoutEffect(() => {
    const onChange: any = ((change: CookieChangeListener) => null);

    globalCookies.addChangeListener(onChange);

    return () => {
      globalCookies.removeChangeListener(onChange);
    };
  }, []);

  return {
    setCookie: globalCookies.set.bind(globalCookies),
    getCookie: globalCookies.get.bind(globalCookies),
    removeCookie: globalCookies.remove.bind(globalCookies),
    cookies: globalCookies.getAll(),
  };
};