import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { Carded, Button, ButtonQty, UploadFile } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { arrowBack } from 'ionicons/icons';
import { removeItem, checkout, addCart } from 'reducers/CartSlice';
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { DielContext } from 'core/DielContext';
import { currency } from 'utils/Helper';

const Cart: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const cart = useAppSelector((state) => state.cart);
  const [presentAlert] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState('checkout');
  const { setShowTabBar, showToast } = useContext(DielContext);
  const [receipt, setReceipt] = useState<any>();

  const handleSubmit = () => {
    setLoading(true);
    dispatch(checkout({ receipt })).then((action) => {
      setLoading(false);
      if (action.payload) {
        showToast('Successfully submitted');
        setScreen('checkout');
        setShowTabBar(true);
        history.push('/orders/pending');
      } else {
        showToast('Something went wrong');
      }
    });
  };

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <Carded
      header={(
        <IonToolbar className={'mainHeaderContainer'}>
          {screen === 'checkout' && (
            <>
              <IonButton
                slot="start"
                fill="clear"
                onClick={() => {
                  history.goBack();
                }}
              >
                <IonIcon
                  className={'headerButton'}
                  src={arrowBack}
                />
              </IonButton>
              <IonTitle
                slot="start"
              >
                Order
              </IonTitle>
            </>
          )}

          {screen === 'payment' && (
            <>
              <IonButton
                slot="start"
                fill="clear"
                onClick={() => {
                  setScreen('checkout');
                }}
              >
                <IonIcon
                  className={'headerButton'}
                  src={arrowBack}
                />
              </IonButton>
              <IonTitle
                slot="start"
              >
                Attach Receipt
              </IonTitle>
            </>
          )}
        </IonToolbar>
      )}
      content={(
        <>
          {screen === 'checkout' && (
            <>
              {cart.items.length === 0 ? (
                <div>No items found</div>
              ) : (
                <div className="title">Packages</div>
              )}

              <IonGrid>
                {cart.items.map((item: any, index: number) => {
                  return (
                    <IonRow key={`cart-item-${index}`}>
                      <IonCol>
                        <IonImg src={item.image} style={{ width: '50px' }} />
                        <IonButton disabled={loading} onClick={() => {
                          dispatch(removeItem(item.key));
                        }}
                        >
                          Remove <IonIcon src={trash} />
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <div>{item.category} {item.name}</div>
                        <div>{currency(item.price)}</div>
                        <div>
                          <ButtonQty value={item.quantity}
                            onChange={(value) => {
                              dispatch(addCart({
                                ...item,
                                quantity: value
                              }));
                            }}
                          />
                        </div>
                      </IonCol>
                    </IonRow>
                  );
                })}
              </IonGrid>
            </>
          )}

          {screen === 'payment' && (
            <div>
              <UploadFile
                value={receipt}
                onChange={(val) => {
                  setReceipt(val);
                }}
              />
            </div>
          )}
        </>
      )}
      footer={(
        <>
          {cart.items.length > 0 && (
            <IonToolbar className="footerToolbar">
              {screen === 'checkout' && (
                <>
                  <div>Total: {currency(cart.total)}</div>
                  <Button
                    label="Continue"
                    slot="end"
                    props={{ color: 'success' }}
                    loading={loading}
                    disabled={loading}
                    onClick={() => {
                      setScreen('payment');
                    }}
                  />
                </>
              )}

              {screen === 'payment' && (
                <>
                  <div>Total: {currency(cart.total)}</div>
                  <Button
                    label="Submit"
                    slot="end"
                    props={{ color: 'success' }}
                    loading={loading}
                    disabled={loading || !receipt}
                    onClick={() => {
                      presentAlert({
                        message: 'Do you want to continue?',
                        buttons: [{
                          text: 'Cancel',
                          role: 'cancel',
                          handler: () => {
                            console.log('cancel');
                          },
                        },
                        {
                          text: 'OK',
                          role: 'confirm',
                          handler: handleSubmit,
                        }],
                      });
                    }}
                  />
                </>
              )}
            </IonToolbar>
          )}
        </>
      )}
    />
  );
};

export default Cart;
