import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { Carded, Button, Profile, Header } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { arrowBack } from 'ionicons/icons';
import { removeItem, checkout } from 'reducers/CartSlice';
import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { DielContext } from 'core/DielContext';

const ProfilePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { setShowTabBar } = useContext(DielContext);

  const handleSave = () => {
    // setLoading(true);
    // dispatch(checkout({})).then((action) => {
    //   console.log(action.payload);
    //   setLoading(false);
    // });
  };

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <Carded
      header={(
        <Header title="Profile" showGoBack showCart={false} showMenu={false} goBack={() => {
          history.goBack();
        }}
        />
      )}
      content={(
        <>
          <Profile />
        </>
      )}
    />
  );
};

export default ProfilePage;
