import React, { useContext, useEffect, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent, IonFooter, IonToolbar, IonTitle, IonButton } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Input } from 'components';
import { DielContext } from 'core/DielContext';
import { createUser, updateUser } from 'reducers/UserSlice';
import { FirebaseService } from 'services';
import { useAppDispatch, useAppSelector } from 'hooks';

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  middlename: yup.string().required(),
  birthdate: yup.string().required(),
  mobile: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  province: yup.string().required(),
  zip: yup.string().required(),
}).required();

const defaultValues = {
  firstname: '',
  lastname: '',
  middlename: '',
  birthdate: '',
  mobile: '',
  address: '',
  city: '',
  province: '',
  zip: '',
};

interface OnboardingModalProps {
  test?: boolean;
}

const Profile: React.FC<OnboardingModalProps> = () => {
  const { currentUser } = FirebaseService.auth;
  const dispatch = useAppDispatch();
  const user: any = useAppSelector((state) => state.user);

  const { setOnboarded, showToast } = useContext(DielContext);
  const { control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const form = watch();
  const [loading, setLoading] = useState(false);

  const handleSave= () => {
    setLoading(true);
    dispatch(updateUser(form))
      .then((action: any) => {
        setLoading(false);

        if (action.payload) {
          setOnboarded(true);
          showToast('Successfully updated');
        } else {
          showToast('Something went wrong');
        }
      });
  };

  useEffect(() => {
    if (user) reset(user);
  }, [user]);

  return (
    <div className="mainWrapper">
      <h5>Personal Details</h5>

      <Controller
        name="firstname"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="First Name"
          />
        )}
      />

      <Controller
        name="lastname"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Last Name"
          />
        )}
      />

      <Controller
        name="middlename"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Middle Name"
          />
        )}
      />

      <Controller
        name="birthdate"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Birth Date"
          />
        )}
      />

      <h5>Contact</h5>

      <Controller
        name="mobile"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Mobile"
          />
        )}
      />

      <h5>Address</h5>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Address"
          />
        )}
      />

      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="City"
          />
        )}
      />

      <Controller
        name="province"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Province"
          />
        )}
      />

      <Controller
        name="zip"
        control={control}
        render={({ field }) => (
          <Input
            props={field}
            placeholder="Zip Code"
          />
        )}
      />

      <IonFooter>
        <Button
          props={{ expand: 'full' }}
          label="Save"
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleSave}
        />
      </IonFooter>
    </div>
  );
};

export default Profile;
