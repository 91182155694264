import { Redirect, Route } from 'react-router';
import { IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, } from '@ionic/react';
import { home, person, list, people } from 'ionicons/icons';

import Cart from 'pages/user/Cart';
import Transactions from 'pages/user/Transactions';
import Account from 'pages/user/Account';
import Settings from 'pages/user/Settings';
import Orders from 'pages/user/Orders';
import Profile from 'pages/user/Profile';
import Activities from 'pages/user/Activities';
import Withdrawals from 'pages/user/Withdrawals';
import Members from 'pages/members/Members';
import MembersOrders from 'pages/members/MembersOrders';
import MemberForm from 'pages/members/MemberForm';
import Diel from 'pages/Diel';
import Mission from 'pages/Mission';
import Vision from 'pages/Vision';
import About from 'pages/About';
import Packages from 'pages/Packages';

import { useContext, useEffect, useState } from 'react';
import { DielContext } from 'core/DielContext';
import { useAppSelector } from 'hooks';
import { memberLabel } from 'utils/Helper';

const UserRoute: React.FC = () => {
  const { type } = useAppSelector((state: any) => state.user);
  const { showTabBar } = useContext(DielContext);

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          {/*<Route exact path="/home"><Home /></Route>*/}
          <Route exact path="/packages"><Packages /></Route>
          <Route exact path="/cart"><Cart /></Route>

          <Route exact path="/diel"><Diel /></Route>
          <Route exact path="/mission"><Mission /></Route>
          <Route exact path="/vision"><Vision /></Route>
          <Route exact path="/about"><About /></Route>

          <Route exact path="/transactions"><Transactions /></Route>
          <Route exact path="/account"><Account /></Route>
          <Route exact path="/settings"><Settings /></Route>
          <Route exact path="/profile"><Profile /></Route>
          <Route exact path="/activities"><Activities /></Route>

          <Route path="/withdrawals/:status" component={Withdrawals} />
          {/*<Route path="/withdrawals" component={Withdrawals} />*/}

          <Route exact path="/members"><Members /></Route>
          <Route exact path="/members/orders"><MembersOrders /></Route>
          <Route exact path="/member/add"><MemberForm /></Route>
          <Route exact path="/member/edit/:id"><MemberForm /></Route>

          <Route path="/orders/:status" component={Orders} />

          {/*
            <Route exact path="/orders/all"><Orders /></Route>
            <Route exact path="/orders/pending"><Orders filter="pending" /></Route>
            <Route exact path="/orders/approved"><Orders filter="approved" /></Route>
            <Route exact path="/orders/shipping"><Orders filter="shipping" /></Route>
            <Route exact path="/orders/completed"><Orders filter="completed" /></Route>
            <Route exact path="/orders/cancelled"><Orders filter="cancelled" /></Route>
          */}

          <Route exact path="/"><Redirect to="/packages" /></Route>
          <Route render={() => <Redirect to="/packages" />} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" style={{ display: showTabBar ? 'inherit' : 'none' }}>
          {/*<IonTabButton tab="home" href="/home">
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>*/}
          <IonTabButton tab="packages" href="/packages">
            <IonIcon icon={list} />
            <IonLabel>Packages</IonLabel>
          </IonTabButton>
          <IonTabButton tab="orders" href="/orders/all">
            <IonIcon icon={list} />
            <IonLabel>Orders</IonLabel>
          </IonTabButton>
          {type !== 'member' && (
            <IonTabButton tab="members" href="/members">
              <IonIcon icon={people} />
              <IonLabel>Members</IonLabel>
            </IonTabButton>
          )}
          <IonTabButton tab="account" href="/account">
            <IonIcon icon={person} />
            <IonLabel>Me</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default UserRoute;
