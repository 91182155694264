import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseService } from 'services';
import { fetcher } from 'utils/Helper';

export const getOrders = createAsyncThunk('orders/getOrders', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;

  const data = await fetcher(`${process.env.REACT_APP_API}/order/list`, token, params);

  if (data.code === 100) {
    return {
      count: data.count,
      rows: data.rows,
      page: data.page,
      pages: data.pages,
      limit: data.limit,
    };
  } else {
    return null;
  }
});

export const getOrder = createAsyncThunk('orders/getOrder', async (orderId: number, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;

  const data = await fetcher(`${process.env.REACT_APP_API}/order/details`, token, { orderId });

  return data;
});


const initialState: any = null;

const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: { ordersReset: () => initialState },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  },
});

export const {
  ordersReset,
} = OrdersSlice.actions;

export default OrdersSlice.reducer;
