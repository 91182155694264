import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseService } from 'services';
import { telegramLog } from 'utils';
import { fetcher } from 'utils/Helper';

export const getWithdrawals = createAsyncThunk('withdrawals/getWithdrawals', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;

  const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/list`, token, params);

  if (data.code === 100) {
    return {
      count: data.count,
      rows: data.rows,
      page: data.page,
      pages: data.pages,
      limit: data.limit,
    };
  } else {
    return null;
  }
});

export const sendRequest = createAsyncThunk('withdrawals/sendRequest', async (params: any, { dispatch, getState } : any) => {
  try {
    const { auth } = FirebaseService;
    const { amount } = params;
    const token = auth.currentUser?.accessToken;

    const data = await fetcher(`${process.env.REACT_APP_API}/withdrawal/createRequest`, token, { amount });

    if (data.code === 200) {
      throw new Error('Something went wrong');
    }

    telegramLog('Withdrawal submitted ❤️');

    return true;
  } catch (e) {
    return false;
  }
});

const initialState: any = null;

const WithdrawalsSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    withdrawalsReset: () => initialState,
    setWithdrawalsFilter: {
      reducer: (state, action: any) => {
        state.filter = action.payload;
      },
      prepare: (filter) => ({ payload: filter }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWithdrawals.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  },
});

export const {
  withdrawalsReset,
  setWithdrawalsFilter,
} = WithdrawalsSlice.actions;

export default WithdrawalsSlice.reducer;
