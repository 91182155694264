import { useEffect, useState, useContext } from 'react';
import { ExploreContainer, Carded, Header } from 'components';
import { DielContext } from 'core/DielContext';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonGrid, IonRow, IonCol, IonSpinner, useIonViewDidLeave, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router';
import ReactPaginate from 'react-paginate';
import { getTransactions } from 'reducers/TransactionsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import moment from 'moment';
import { currency } from 'utils/Helper';

const Transactions: React.FC = () => {
  const { balance } = useAppSelector((states: any) => states.user);
  const transactions = useAppSelector((states: any) => states.transactions);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setShowTabBar } = useContext(DielContext);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const generateTransactions = async (page = 0) => {
    dispatch(getTransactions({ page })).then((action: any) => {
      const result = action.payload;

      if (result) {
        if (page === 0) {
          setData(result.rows);
        } else {
          setData([...data, ...result.rows]);
        }
      }
    });
  };

  const handleScroll = (e: any) => {
    if (transactions.page + 1 < transactions.pages) {
      generateTransactions(transactions.page + 1);
    }
    setTimeout(() => e.target.complete(), 500);
  };

  useIonViewDidEnter(() => {
    setShowTabBar(false);

    generateTransactions();
  }, []);

  useIonViewDidLeave(() => {
    setShowTabBar(true);
  });

  // useEffect(() => {
  //   setLoading(true);

  //   dispatch(getTransactions({ })).then((action: any) => {
  //     setLoading(false);
  //   });

  //   return (() => {
  //     setShowTabBar(true);
  //   });
  // }, []);

  return (
    <Carded
      header={(
        <Header title="Transactions" showGoBack showCart={false} showMenu={false} goBack={() => {
          history.goBack();
        }}
        />
      )}
      content={(
        <>
          <div className="ion-margin-top">
            <div className="ion-margin-bottom">
              Wallet: {currency(balance)}
            </div>

            {loading ? (
              <>
                <IonSpinner
                  name={'lines-sharp'}
                  color={'#ccc'}
                >
                </IonSpinner>
              </>
            ) : (
              <>
                {data.length === 0 ? (
                  <div>No transactions found</div>
                ) : (
                  <>
                    <IonList>
                      {data.map((transaction: any) => {
                        return (
                          <div key={`transaction-${transaction.id}`} className="transactionContainer">
                            <IonGrid>
                              <IonRow>
                                <IonCol size="8">
                                  <div className="textSmall">{moment(transaction.createdAt).format('MM/DD/YYYY h:mm a')}</div>
                                  <div>{transaction.type}</div>
                                </IonCol>
                                <IonCol size="4" className="ion-text-right">
                                  <div>{currency(transaction.amount)}</div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        );
                      })}
                    </IonList>

                    <IonInfiniteScroll
                      onIonInfinite={handleScroll}
                    >
                      <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    />
  );
};

export default Transactions;
