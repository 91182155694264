import { LayoutUser } from 'components';

const Vision: React.FC = () => {
  return (
    <LayoutUser>
      <h3>Vision</h3>

      <p>To become the trusted perfume company that offers the best income opportunities and marketing plans to lead the industry by  producing outstanding quality perfumes at an affordable price to suit the needs of the Filipinos .</p>
    </LayoutUser>
  );
};

export default Vision;
