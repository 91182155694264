import React, { useContext, useEffect, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent, IonFooter, IonToolbar, IonTitle, IonButton } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Header, Input } from 'components';
import { DielContext } from 'core/DielContext';
import { prepareUser } from 'reducers/UserSlice';
import { FirebaseService } from 'services';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  middlename: yup.string().required(),
  birthdate: yup.string().required(),
});

const defaultValues = {
  firstname: '',
  lastname: '',
  middlename: '',
  birthdate: '',
};

const Profile: React.FC = () => {
  const { currentUser } = FirebaseService.auth;
  const dispatch = useAppDispatch();
  const user: any = useAppSelector((state) => state.user);
  const history = useHistory();

  const { setOnboarded, showToast } = useContext(DielContext);
  const { control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const form = watch();
  const [loading, setLoading] = useState(false);

  const handleSave= () => {
    setLoading(true);
    dispatch(prepareUser(form));

    history.push('/contact');
  };

  useEffect(() => {
    if (user) reset(user);
  }, [user]);

  return (
    <div>
      <Header title="Personal Details" showCart={false} showMenu={false} />

      <div className="mainWrapper">
        <div style={{ flex: 1 }}>
          <Controller
            name="firstname"
            control={control}
            render={({ field }) => (
              <Input
                label="First Name"
                props={field}
                placeholder="First Name"
              />
            )}
          />

          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <Input
                label="Last Name"
                props={field}
                placeholder="Last Name"
              />
            )}
          />

          <Controller
            name="middlename"
            control={control}
            render={({ field }) => (
              <Input
                label="Middle Name"
                props={field}
                placeholder="Middle Name"
              />
            )}
          />

          <Controller
            name="birthdate"
            control={control}
            render={({ field }) => (
              <Input
                label="Birthday"
                props={field}
                placeholder="Birth Date"
              />
            )}
          />
        </div>

        <div>
          <IonFooter>
            <Button
              props={{ expand: 'full' }}
              label="Next"
              disabled={!isValid || loading}
              loading={loading}
              onClick={handleSave}
            />
          </IonFooter>
        </div>
      </div>
    </div>
  );
};

export default Profile;
