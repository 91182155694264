import { IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

interface InputProps {
  label?: string;
  icon?: string;
  className?: string;
  placeholder?: string;
  props?: any;
  value?:any;
  onChange?: (e: any) => void;
}

const Input: React.FC<InputProps> = ({
  label,
  icon,
  placeholder,
  className = '',
  value,
  onChange,
  props = {},
}) => {
  // const _onChange = onChange ? onChange : props.onChange;

  return (
    <div className="inputWrapper">
      {label && (
        <div>{label}</div>
      )}
      <div className={`inputContainer ${className}`}>
        <IonItem className="input">
          {icon && (
            <IonLabel><IonIcon src={icon} /></IonLabel>
          )}
          <IonInput placeholder={placeholder} {...props} value={props.value || value} onIonChange={props.onChange || onChange} />
        </IonItem>
      </div>
    </div>
  );
};

export default Input;
