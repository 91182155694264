import { useIonViewDidEnter } from '@ionic/react';
import { LayoutUser } from 'components';
import { DielContext } from 'core/DielContext';
import { useContext } from 'react';

const Mission: React.FC = () => {
  const { setShowTabBar } = useContext(DielContext);

  useIonViewDidEnter(() => {
    setShowTabBar(true);
  }, []);

  return (
    <LayoutUser>
      <h3>Mission</h3>

      <p>Our mission is to offer the best job of building a part-time or full-time business to any interested Filipinos with affordable initial investment.
        We believe that starting this business requires passion and commitment as we also strive to produce the best quality of perfume .
      </p>
    </LayoutUser>
  );
};

export default Mission;
