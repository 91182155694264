import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonMenuButton, IonImg, IonTitle } from '@ionic/react';
import { menu, person, logIn, cart, arrowBack } from 'ionicons/icons';
import { useAppSelector } from 'hooks';
import diel from 'assets/images/diel.png';
import { useHistory } from 'react-router';

interface HeaderProps {
  collapse?: any;
  size?: string;
  title?: string;
  goBack?: () => void;
  showGoBack?: boolean;
  showCart?: boolean;
  showMenu?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  collapse,
  size,
  title,
  goBack,
  showGoBack = false,
  showCart = true,
  showMenu = true,
}) => {
  const { uid, type } = useAppSelector((states: any) => states.user);
  const cartItems = useAppSelector((state) => state.cart);
  const history = useHistory();

  return (
    <IonHeader collapse={collapse}>
      <IonToolbar
        className={`mainHeaderContainer header-${type}`}
      >
        {showGoBack && (
          <IonButtons
            slot={'start'}
          >
            <IonButton
              onClick={() => {
                if (goBack) {
                  goBack();
                } else {
                  history.goBack();
                }
              }}
            >
              <IonIcon
                className={'headerButton'}
                src={arrowBack}
              />
            </IonButton>
          </IonButtons>
        )}

        {title ? (
          <IonTitle className={`${showGoBack? '' : 'ion-padding'}`}>{title}</IonTitle>
        ) : (
          <IonImg src={diel} style={{
            width: '40px',
            marginLeft: '6px',
          }}
          />
        )}

        <IonButtons
          slot={'end'}
        >
          {!uid ? (
            <IonButton
              routerLink={'/login'}
              className={'headerButton'}
            >
              Login
            </IonButton>
          ) : (
            <>
              {showCart && (
                <IonButton
                  routerLink={'/cart'}
                >
                  <IonIcon
                    className={'headerButton'}
                    src={cart}
                  />
                  {cartItems.items.length}
                </IonButton>
              )}
            </>
          )}

          {showMenu && (
            <IonMenuButton
              className={'headerButton'}
              menu="appMenu"
            >
              <IonIcon
                className={'headerButton'}
                src={menu}
              />
            </IonMenuButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;


