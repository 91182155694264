import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FirebaseService } from 'services';
import moment from 'moment';
import { fetcher } from 'utils/Helper';

export const createUser = createAsyncThunk('user/createUser', async ({ token, params }: any, { dispatch, getState } : any) => {
  await fetcher(`${process.env.REACT_APP_API}/user/registerAccount`, token, {
    ...params,
    parentUid: null,
    createdAt: moment().format(),
  });

  // console.log('createUser', `${process.env.REACT_APP_API}/user/registerAccount`, response.data);

  // if (response.data?.code === 100) {
  //   const data = await axios.post(`${process.env.REACT_APP_API}/user/details`, { token })
  //     .then((response: any) => {
  //       return response.data;
  //     });

  //   if (data.code === 200) {
  //     console.log('logout');
  //     dispatch(logout({}));
  //     return null;
  //   } else if (data.details.status === 'incomplete') {
  //     dispatch(setUser(data.details));
  //     return false;
  //   } else {
  //     dispatch(setUser(data.details));
  //     return true;
  //   }
  // } else {

  // }

  return true;
});

export const updateUser = createAsyncThunk('user/updateUser', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;
  const token = auth.currentUser?.accessToken;
  const { user } = getState();

  dispatch(prepareUser(params));

  const data = await fetcher(`${process.env.REACT_APP_API}/user/updateAccountByUser`, token, {
    ...user,
    ...params,
  });

  console.log('data', data);

  if (data.code === 100) {
    dispatch(setUser({ status: 'pending' }));
    return true;
  } else {
    return false;
  }
});

export const loadUser = createAsyncThunk('user/loadUser', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;

  const data = await fetcher(`${process.env.REACT_APP_API}/user/details`, auth.currentUser?.accessToken, {});

  if (data.code === 200) {
    console.log('logout');
    dispatch(logout({}));
    return null;
  } else if (data.details.status === 'incomplete') {
    dispatch(setUser(data.details));
    return false;
  } else {
    dispatch(setUser(data.details));
    return true;
  }
});

export const refreshUser = createAsyncThunk('user/refreshUser', async (params: any, { dispatch, getState } : any) => {
  const { auth } = FirebaseService;

  const data = await fetcher(`${process.env.REACT_APP_API}/user/details`, auth.currentUser?.accessToken, {});

  if (data.code === 100) {
    await dispatch(setUser(data.details));
  }

  return data;
});

export const logout = createAsyncThunk('user/logout', async (params: any, { dispatch } : any) => {
  const { auth } = FirebaseService;
  auth.signOut();

  console.log('logout');

  return dispatch(userReset());
});

const initialState = {};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userReset: () => initialState,
    setUser: {
      reducer: (state, action: any) => {
        return {
          ...state,
          ...action.payload
        };
      },
      prepare: (params) => ({ payload: params }),
    },
    prepareUser: {
      reducer: (state, action: any) => {
        return {
          ...state,
          ...action.payload
        };
      },
      prepare: (params) => ({ payload: params }),
    },
  },
  extraReducers: (builder) => {
    // // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // builder.addCase(setUserDataFirebase.fulfilled, (state, action: any) => {
    //   state.user.email = action.payload.email;
    //   state.user.uid = action.payload.uid;
    //   state.user.displayName = action.payload.displayName;
    //   state.loggedIn = action.payload.loggedIn;
    // });
  },
});

export const {
  userReset,
  setUser,
  prepareUser,
} = UserSlice.actions;

export default UserSlice.reducer;