import React, { useContext, useEffect, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol, IonImg, IonModal, IonContent, IonFooter, IonToolbar, IonTitle, IonButton } from '@ionic/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Header, Input } from 'components';
import { DielContext } from 'core/DielContext';
import { prepareUser } from 'reducers/UserSlice';
import { FirebaseService } from 'services';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useHistory } from 'react-router';

const schema = yup.object().shape({
  mobile: yup.string().required(),
}).required();

const defaultValues = {
  mobile: '',
};


const Profile: React.FC = () => {
  const dispatch = useAppDispatch();
  const user: any = useAppSelector((state) => state.user);
  const history = useHistory();

  const { setOnboarded, showToast } = useContext(DielContext);
  const { control, formState, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid } = formState;
  const form = watch();
  const [loading, setLoading] = useState(false);

  const handleSave= () => {
    setLoading(true);
    dispatch(prepareUser(form));

    history.push('/address');
  };

  useEffect(() => {
    if (user) reset(user);
  }, [user]);

  return (
    <div>
      <Header title="Contact" showGoBack showCart={false} showMenu={false} />

      <div className="mainWrapper">
        <Controller
          name="mobile"
          control={control}
          render={({ field }) => (
            <Input
              label="Mobile Number"
              props={field}
              placeholder="Mobile"
            />
          )}
        />

        <IonFooter>
          <Button
            props={{ expand: 'full' }}
            label="Next"
            disabled={!isValid || loading}
            loading={loading}
            onClick={handleSave}
          />
        </IonFooter>
      </div>
    </div>
  );
};

export default Profile;
