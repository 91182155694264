import React from 'react';
import { IonMenu, IonContent, IonMenuToggle, IonItem, IonFooter, IonPage } from '@ionic/react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { logout } from 'reducers/UserSlice';
import { Button } from 'components';

interface MenuProps {
  props?: any;
}

const Menu: React.FC<MenuProps> = () => {
  const dispatch = useAppDispatch();
  const { uid } = useAppSelector((states: any) => states.user);

  return (
    <IonMenu
      contentId="mainContent"
      menuId="appMenu"
      id="appMenu"
      className="menuContainer"
      side="end"
    >
      <IonPage>
        <IonContent>
          <IonMenuToggle menu="appMenu">
            <IonItem
              routerLink="/diel"
              className="menuItem"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <span
                className={'menuName'}
              >
                Our Company
              </span>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle menu="appMenu">
            <IonItem
              routerLink="/about"
              className="menuItem"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <span
                className={'menuName'}
              >
                About Us
              </span>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle menu="appMenu">
            <IonItem
              routerLink="/vision"
              className="menuItem"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <span
                className={'menuName'}
              >
                Vision
              </span>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle menu="appMenu">
            <IonItem
              routerLink="/mission"
              className="menuItem"
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <span
                className={'menuName'}
              >
                Mission
              </span>
            </IonItem>
          </IonMenuToggle>
        </IonContent>

        <IonFooter>
          <div className="ion-padding">
            {uid && (
              <IonMenuToggle menu="appMenu">
                <Button
                  label="Sign Out"
                  onClick={() => {
                    dispatch(logout({}));
                  }}
                />
              </IonMenuToggle>
            )}
          </div>
        </IonFooter>
      </IonPage>
    </IonMenu>
  );
};

export default Menu;
