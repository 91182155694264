import { useIonViewDidEnter } from '@ionic/react';
import { LayoutUser } from 'components';
import { DielContext } from 'core/DielContext';
import { useContext } from 'react';

const About: React.FC = () => {
  const { setShowTabBar } = useContext(DielContext);

  useIonViewDidEnter(() => {
    setShowTabBar(true);
  }, []);

  return (
    <LayoutUser>
      <h3>About Us</h3>
      <p>CREATING TRUST AND UNIQUE BRAND IDENTITY THROUGH  PERFUMES WHICH ARE MORE THAN JUST A SMELL  BUT HAVE THE POWER TO EVOKE DIFFERENT FEELINGS</p>
      <p>Just over three months ago, we became fascinated by the idea of discovering the great life through someone else’s scent. It may sound and smell crazy, but we wanted to build a community with faithfulness and peaceful feeling. While there are many ideas to make clothes, shoes and bags, we realized that the best way to express individuality is by wearing nothing visible but only something that will last… A scent.</p>
      <p>A fashion may be worth a thousand words, but wearing Diel Parfum can take you to the world and show you around with confidence.</p>
    </LayoutUser>
  );
};

export default About;
