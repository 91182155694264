import { IonButton, IonSpinner, IonIcon } from '@ionic/react';
import React from 'react';

interface ButtonProps {
  label: string;
  icon?:string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  slot?: string;
  color?: string;
  props?: any;
  expand?: string;
}

const Button: React.FC<ButtonProps> = ({
  label = '',
  icon,
  loading = false,
  disabled = false,
  slot,
  onClick = () => {},
  color = 'primary',
  expand = 'block',
  props = {},
}) => {
  return (
    <div className="button" slot={slot}>
      <IonButton {...props} shape="round" expand={expand} disabled={disabled} color={color} onClick={onClick}>
        {loading ?
          <IonSpinner name="crescent" /> :
          <>
            {icon && (
              <IonIcon
                src={icon}
                style={{ marginRight: '4px' }}
              />
            )}
            { label }
          </>}
      </IonButton>
    </div>
  );
};

export default Button;
