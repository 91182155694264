import { useEffect, useState, useContext } from 'react';
import { ExploreContainer, Carded } from 'components';
import { DielContext } from 'core/DielContext';
import { IonToolbar, IonButton, IonIcon, IonTitle } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useHistory } from 'react-router';

const Activities: React.FC = () => {
  const history = useHistory();
  const { setShowTabBar } = useContext(DielContext);

  useEffect(() => {
    setShowTabBar(false);

    return (() => {
      setShowTabBar(true);
    });
  }, []);

  return (
    <Carded
      header={(
        <IonToolbar className={'mainHeaderContainer'}>
          <IonButton
            slot="start"
            fill="clear"
            onClick={() => {
              history.goBack();
            }}
          >
            <IonIcon
              className={'headerButton'}
              src={arrowBack}
            />
          </IonButton>
          <IonTitle
            slot="start"
          >
            Activities
          </IonTitle>
        </IonToolbar>
      )}
      content={(
        <>
          Todo
        </>
      )}
    />
  );
};

export default Activities;
