import { IonInfiniteScroll, IonInfiniteScrollContent, IonCol, IonGrid, IonIcon, IonImg, IonButton, IonRow, IonSpinner, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewDidLeave, IonHeader, IonItem, IonRouterLink, IonList } from '@ionic/react';
import { arrowBack, list, arrowForward, car, paperPlane, send, wallet, checkmark, close, trash } from 'ionicons/icons';
import { Carded, MenuButton, Header } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getOrders, getOrder } from 'reducers/OrdersSlice';
import { useEffect, useState, useContext } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { DielContext } from 'core/DielContext';
import { currency } from 'utils/Helper';
import { OrderModal } from 'components';
import { uuidv4 } from '@firebase/util';

interface OrdersProps extends RouteComponentProps {
  temp?: string;
  match: any;
}

const Orders: React.FC<OrdersProps> = ({ match }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [order, setOrder] = useState([]);
  const [data, setData] = useState<any[]>([]);
  const { setShowTabBar } = useContext(DielContext);
  const orders = useAppSelector((state: any) => state.orders);
  let status = match.params.status || '';
  if (status === 'all') status = '';

  const generateTransactions = async (page = 0) => {
    dispatch(getOrders({
      status,
      page
    })).then((action: any) => {
      const result = action.payload;

      if (result) {
        if (page === 0) {
          setData(result.rows);
        } else {
          setData([...data, ...result.rows]);
        }
      }
    });
  };

  const handleScroll = (e: any) => {
    if (orders.page + 1 < orders.pages) {
      generateTransactions(orders.page + 1);
    }
    setTimeout(() => e.target.complete(), 500);
  };

  const handleOrderDetails = (order: any) => {
    dispatch(getOrder(order.id))
      .then((action: any) => {
        setOrder(action.payload.order);
        setShowDetails(true);
      });
  };

  // useIonViewDidEnter(() => {
  //   console.log('useIonViewDidEnter');
  //   generateTransactions();
  // });

  useIonViewDidLeave(() => {
    setData([]);
  });

  useIonViewDidEnter(() => {
    setShowTabBar(true);
    generateTransactions();
  }, [status]);

  return (
    <Carded
      header={<Header title="Orders" />}
      content={(
        <>
          {loading ? (
            <>
              <IonSpinner
                name={'lines-sharp'}
                color={'#ccc'}
              >
              </IonSpinner>
            </>
          ) : (
            <>
              <IonHeader className="ion-no-border">
                <IonGrid>
                  <IonRow>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/all" className={status === '' ? 'active' : ''} label="All" icon={list} />
                    </IonCol>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/pending" className={status === 'pending' ? 'active' : ''} label="Pending" icon={wallet} />
                    </IonCol>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/approved" className={status === 'approved' ? 'active' : ''} label="Approved" icon={car} />
                    </IonCol>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/shipping" className={status === 'shipping' ? 'active' : ''} label="Shipping" icon={car} />
                    </IonCol>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/completed" className={status === 'completed' ? 'active' : ''} label="Completed" icon={checkmark} />
                    </IonCol>
                    <IonCol size="3">
                      <MenuButton routerLink="/orders/cancelled" className={status === 'cancelled' ? 'active' : ''} label="Cancelled" icon={trash} />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonHeader>

              <div className="ion-margin-top">
                {data.length === 0 ? (
                  <div>No orders found</div>
                ) : (
                  <>
                    <IonList>
                      {data.map((item: any) => {
                        return (
                          <div key={`order-${item.id}-${uuidv4()}`} className="orderContainer">
                            <IonGrid>
                              <IonRow onClick={() => {
                                handleOrderDetails(item);
                              }}
                              >
                                <IonCol>
                                  <div>{moment(item.createdAt).format('MM/DD/YYYY h:mm a')}</div>
                                  <div className="title">{currency(item.total ?? 0)}</div>
                                </IonCol>
                                <IonCol className="ion-text-right">
                                  <div>{item.status}</div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </div>
                        );
                      })}
                    </IonList>

                    <IonInfiniteScroll
                      onIonInfinite={handleScroll}
                    >
                      <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </>
                )}
              </div>

              <OrderModal order={order} setIsOpen={setShowDetails} isOpen={showDetails} />
            </>
          )}
        </>
      )}
    />
  );
};

export default Orders;
