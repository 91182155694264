import React, { useEffect, useState } from 'react';
import { ref, off, onValue } from 'firebase/database';
import { FirebaseService } from 'services';
import { useAppDispatch } from 'hooks';
import { loadUser } from 'reducers/UserSlice';
import { IonToast } from '@ionic/react';
import { OnboardingModal } from 'components';

export const DielContext = React.createContext<any>({
  agents: [],
  showToast: () => null,
  showMessage: () => null,
  showTabBar: true,
  setShowTabBar: (value: boolean) => null,
  onboarded: true,
  setOnboarded: (value: boolean) => null,
});

const toastDefault: any = {
  message: '',
  show: false,
  duration: 800,
  color: null,
  position: 'top',
  header: '',
  animated: true,
};

const messageDefault: any = {
  showMessage: false,
  message: '',
  title: 'Message',
  confirmCallback: null,
};

export const DielProvider: React.FC<any> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState<any>({});
  const [packages, setPackages] = useState<any>();
  const [toastVal, setToastVal] = useState(toastDefault);
  const [messageVal, setMessageVal] = useState(messageDefault);
  const [showTabBar, setShowTabBar] = useState(true);
  const [onboarded, setOnboarded] = useState(true);
  const { db, auth } = FirebaseService;
  // const { currentUser } = auth;

  const showToast = (message: string, position = 'top') => {
    setToastVal({
      ...toastDefault,
      message,
      show: true,
      position,
    });
  };

  const hideToast = () => {
    setToastVal({
      ...toastDefault,
      show: false,
    });
  };

  const showMessage = (params: any) => {
    setMessageVal({
      ...messageDefault,
      ...params,
      showMessage: true,
    });
  };

  // const hideMessage = () => {
  //   setMessageVal({
  //     ...messageDefault,
  //     showMessage: false,
  //   });
  // };

  useEffect(() => {
    if (db) {
      const itemsRef = ref(db, '/items');
      onValue(itemsRef, (snap) => {
        setItems(snap.val() ?? {});
      });

      const packagesRef = ref(db, '/packages');
      onValue(packagesRef, (snap) => {
        setPackages(snap.val() ?? {});
      });

      return (() => {
        off(ref(db, '/items'));
        off(ref(db, '/packages'));
      });
    }
  }, [db]);

  useEffect(() => {
    if (auth?.currentUser) {
      const checkOnboarding = async() => {
        setOnboarded(true);

        await dispatch(loadUser(auth?.currentUser.uid))
          .then((action: any) => {
            if (action.payload !== null) {
              setOnboarded(action.payload);
            }
          });
      };

      checkOnboarding();
    }
  }, [auth?.currentUser]);

  return (
    <DielContext.Provider
      value={{
        items,
        packages,
        showToast,
        showMessage,
        showTabBar,
        setShowTabBar,
        onboarded,
        setOnboarded,
      }}
    >
      { children }

      <IonToast
        isOpen={toastVal.show}
        onDidDismiss={() => hideToast()}
        header={toastVal.header}
        message={toastVal.message}
        duration={toastVal.duration}
        position={toastVal.position}
        color={toastVal.color}
        animated
      />

      {/*<OnboardingModal />*/}
    </DielContext.Provider>
  );
};
