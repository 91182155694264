import { IonPage, IonContent, IonSpinner, IonImg } from '@ionic/react';
import diel from 'assets/images/diel.png';

const Splash: React.FC = () => {
  return (
    <IonPage>
      <IonContent
        className={'pageBgGrey'}
        fullscreen
      >
        <div
          className={'containerCenter defaultPage'}
        >
          <div
            className={'splashContainer'}
          >
            <IonImg src={diel} style={{ width: '200px' }} />

            <div className="splashSpinner">
              <IonSpinner
                name={'lines-sharp'}
                color={'#FFFFFF'}
              >
              </IonSpinner>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;
